import React from 'react'

const ProjectDisplay = ( {project} ) => {
  return (
    <div>
        <div key={project.id} className="sm:flex py-5 pl-6">
          <div className='bg-gray-200 space-y-2 w-[350px] h-fit sm:w-[900px] border-r-4px'>
              <p className='font-poppins text-bold text-[32px]'>{project.title}</p>
              <p>Date of Completion: {project.year}</p>
              <p>Description: {project.description}</p>
              <div className="flex">
                <a href={project.github} target="_blank" rel="noreferrer"><img className='hover:scale-105 duration-300 cursor-pointer m-1'src={project.github_icon} alt=""/></a>
              </div>
              <div className="flex flex-wrap">
                <img className="m-1" src={project.icon1} alt=''/>
                <img className="m-1" src={project.icon2} alt=''/>
                <img className="m-1" src={project.icon3} alt=''/>
                <img className="m-1" src={project.icon4} alt=''/>
              </div>
          </div>
          <img src={project.image} alt="" className='h-[300px] w-[345px] lg:h-1/5 lg:w-[700px] py-5 md:mx-auto sm:pl-10' />
        </div>
    </div>
  )
}

export default ProjectDisplay