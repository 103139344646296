
import { projects } from '../constants';
import { useState, useEffect } from 'react';
import ProjectDisplay from '../components/ProjectDisplay';
import Filter from '../components/Filter';

const Projects = () => {

  const [item, setItem] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [category, setCategory] = useState(0);
  // Mistake: Don't fetch a JS array, usually fetch is for a url

  useEffect(() => {
    fetchProjectList();
  }, []);

  const fetchProjectList =  () => {
    const data = projects;
    data.sort((a,b) => b.id - a.id)
    setItem(data);
    setFiltered(data);
  }

  return (
    <section>
       <div >
        <Filter item={item} setFiltered={setFiltered} category={category} setCategory={setCategory}/>
      </div>
      <div className='min-h-screen'>
        {filtered.map((project) => {
          return <ProjectDisplay key={project.id} project={project}  />
        })}
      </div>
     
    </section>
  )
}

export default Projects