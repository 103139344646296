export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "experience",
    title: "Experience",
  },
  {
    id: "leadership",
    title: "Leadership",
  },
];


export const projects = [
  {
    id: 1,
    project_type: "Game",
    title: "Pong-Game",
    year: "Sep 2022",
    image: "/assets/pong.png",
    description: "A game centered around a pong that bounces between two paddles. If the pong goes out of bound on one side, the other player scores a point. Left Paddle controlled with w-a-d-s and right paddle controlled with up-right-down-left arrow. First to 5 wins",
    github: "https://github.com/Khaijutsu/Pong-Game",
    github_icon: "https://img.shields.io/badge/Github-black?style=for-the-badge&logo=github&logoColor=white",
    icon1: "https://img.shields.io/badge/Python-blue?style=for-the-badge&logo=Python&logoColor=gold"
  },
  {
    id: 2,
    project_type: "Web App",
    title: "E-commerce Website",
    year: 'Oct 2022',
    image: "/assets/ecommerce.png",
    description: "A model ecommerece store that sort items into categories. Clicking on one of the buttons of categories will retrieve the respective items. For example, clicking the clothing button will filter for products that are clothes. The items are obtained from an external API.",
    github: "https://github.com/Khaijutsu/BargainPros",
    github_icon: "https://img.shields.io/badge/Github-black?style=for-the-badge&logo=github&logoColor=white",
    icon1: "https://img.shields.io/badge/React-teal?style=for-the-badge&logo=react&logoColor=white",
    icon2: "https://img.shields.io/badge/Node.js-2D5A27?style=for-the-badge&logo=next.js&logoColor=white"
  },
  {
    id: 3,
    project_type: "School Web App",
    title: "Restaurant Chain Table Reservation",
    year: "Nov 2022",
    image: "/assets/res-app-home.png",
    description: "A website that allows users to make a table reservation. The user is able to select a date, time, party size, and select from the avaiable tables.  If the user selects a party size and there isn't a table with the exact capacity, then the system would merge tables to accomdate the size. The notification is sent to the owner/employees to move the tables. The user is then prompted to enter their name, phone and email. The website also provide users a way to create an account, which is then stored in MongoDB.",
    github: "https://github.com/Khaijutsu/TableReservation",
    github_icon: "https://img.shields.io/badge/Github-black?style=for-the-badge&logo=github&logoColor=white",
    icon1: "https://img.shields.io/badge/MongoDB-green?style=for-the-badge&logo=mongodb&logoColor=white",
    icon2: "https://img.shields.io/badge/Express.js-yellow?style=for-the-badge&logo=express&logoColor=white",
    icon3: "https://img.shields.io/badge/React-teal?style=for-the-badge&logo=react&logoColor=white",
    icon4: "https://img.shields.io/badge/Node.js-2D5A27?style=for-the-badge&logo=next.js&logoColor=white"
  },
  {
    id: 4,
    project_type: "Web App",
    title: "Budget App",
    year: "Sep 2023",
    image: "/assets/budget-app.png",
    description: "A budget web application that tracks incomes and expenses. User can add incomes and expenses in its own respective component and be updated in the dashboard. The dashboard component would illustrate a graph that updates dynamically, recent history of added items, and current total amount.",
    github: "https://github.com/Khaijutsu/Budget-App",
    github_icon: "https://img.shields.io/badge/Github-black?style=for-the-badge&logo=github&logoColor=white",
    icon1: "https://img.shields.io/badge/React-teal?style=for-the-badge&logo=react&logoColor=white",
    icon2: "https://img.shields.io/badge/Node.js-2D5A27?style=for-the-badge&logo=next.js&logoColor=white",
    icon3: "https://img.shields.io/badge/mySQL-00758f?style=for-the-badge&logo=mySQL&logoColor=orange",
  }, 
  {
    id: 5,
    project_type: "School Web App",
    title: "Fuel App",
    year: "In Progress",
    image: "/assets/fuel.png",
    description: "Prompt: A partner of your company has requested to build a software application that will predict the rate of the fuel based on the following criteria: Client Location (in-state or out-of-state), Client history (existing customer with previous purchase or new), Gallons requested, Company profit margin (%)",
    github: "https://github.com/Khaijutsu/Fuel-App",
    github_icon: "https://img.shields.io/badge/Github-black?style=for-the-badge&logo=github&logoColor=white",
    icon1: "https://img.shields.io/badge/MongoDB-green?style=for-the-badge&logo=mongodb&logoColor=white",
    icon2: "https://img.shields.io/badge/Express.js-yellow?style=for-the-badge&logo=express&logoColor=white",
    icon3: "https://img.shields.io/badge/React-teal?style=for-the-badge&logo=react&logoColor=white",
    icon4: "https://img.shields.io/badge/Node.js-2D5A27?style=for-the-badge&logo=next.js&logoColor=white"
  }
];

export const experiences = [
  {
    id: 1,
    company: "HEB",
    title: "Parking Lot Attendant",
    year: "Jun 2021 - Jan 2022",
    task1: "Pushed around 100-500 carts per day into a section for customers to use",
    task2: "Interact with customers and making sure that they've the best shopping experience",
    task3: "Teach new hires on the process of where the carts go and how to close the store for night shift",
    image: "/assets/cart.jpg"
  },
  {
    id: 2,
    company: "UH Recreation Center",
    title: "Operation Lead",
    year: "Sep 2020 - Dec 2020",
    task1: "Oversaw daily operations of the recreation center, including managing a team of 3 staff members and ensuring smooth flow of over 1,000 daily patrons",
    task2: "Successfully organized and set up over 5 University events, ensuring timely delivery of materials, equipment, and supplies, resulting in increased efficiency and customer satisfaction",
    task3: "Successfully organized and set up over 5 University events, ensuring timely delivery of materials, equipment, and supplies, resulting in increased efficiency and customer satisfaction",
    image: "/assets/recreation.png"
  }
];

export const leaderships = [
  {
    id: 1,
    title: "Corporate Director",
    org: "CougarCS",
    link: "https://cougarcs.com/",
    year: "May 2021 - May 2022",
    task1: "Forged relationships with several companies by writing refined emails to host informational sessions to provide opportunities for the student body",
    task2: "Coordinated monthly events with 12 other directors to ensure CougarCS runs smoothly",
    task3: "Implemented an organized google drive with google sheets to keep track of the status of the companies as well as documentation for future officers",
    image: "/assets/CougarCS.jpg"
  },
  {
    id: 2,
    title: "Administrative Director",
    org: "Asian Business Student Association(ABSA)",
    link: "https://www.uhabsa.org/",
    year: "Jan 2021 - May 2021",
    task1: "Ensured that over 100 members got their points for attending professional, social, and community events through google sheets",
    task2: "Communicated with 24 other officers to establish an organized environment for each event by preparing Google Forms for each event for attendance",
    task3: "Hosted a technical workshop that focused on the basics of HTML, CSS, and JavaScript with an attendance of about 70 members",
    image: "/assets/ABSA.jpg",
  }
];