import React from 'react'
import {AiOutlineLinkedin, AiOutlineGithub} from 'react-icons/ai'
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='mt-auto pt-4 bg-stone-100'>
      <div className='flex items-center justify-center space-x-4'>
        <a href="https://www.linkedin.com/in/erickkhaipham/" target="_blank"  rel="noreferrer" ><AiOutlineLinkedin size={20}/></a>
        <a href="https://github.com/Khaijutsu" target="_blank" rel="noreferrer" ><AiOutlineGithub size={20}/></a>
      </div>
      
      <p className='flex justify-center pt-2'>@{ currentYear } Erick Khai Pham</p>
    </div>
  )
}

export default Footer
