import { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [state, handleSubmit] = useForm("mwkdwbvj");
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');

  const handleInputChange1 = (e) => {
    setInput1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setInput2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setInput3(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    try {

      if (handleSubmit(e)) {
        setInput1('');
        setInput2('');
        setInput3('');
        toast.success('Form submitted successfully!', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
      else if (state.error) {
        toast.success('Form submission failed!', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (err) {
      // Handle any errors that occur during form submission
      console.error('Form submission error:', err);
      toast.error('Form submission error!', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  
  return (
      <form onSubmit={handleFormSubmit}>
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto'>
          <h1 className='text-center'>Contact Form</h1>
          <div className='h-screen text-left flex flex-col justify-center gap-2'>
            <h1 className='text-white text-[30px]'>Contact</h1>
            {/* Name */}
            <label className='text-white'>Name</label>
            <input id="name" type="text" name="name" placeholder="First and Last" value={input1} onChange={handleInputChange1} required/>
            <ValidationError 
            prefix="Name" 
            field="text"
            errors={state.errors}
            />
            {/* Email */}
            <label htmlFor="email" className='text-white'>Email Address</label>
            <input id="email" type="email" name="email"  placeholder="Email" value={input2} onChange={handleInputChange2} required/>
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
              />
            {/* Message */}
            <label className='text-white'>Message</label>
            <textarea id="mesage" name="message"  placeholder="Message" value={input3} onChange={handleInputChange3} required/>

            <ValidationError prefix="Message" field="message" errors={state.errors}/>
            <button  className='bg-blue-400 mt-4' type="submit" disabled={state.submitting}>
              Submit
            </button>
            <ToastContainer />
          </div>
        </div>
      </form>
 
  )
}

export default Contact