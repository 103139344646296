import React from "react";
import Typed from "react-typed";

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold p-2">
          Hello I'm
        </h1>
        <h1 className="text-[#00df9a] md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Erick Khai Pham
        </h1>
        <p className="md:text-4xl sm:text-3xl text-xl font-bold md:py-6">
          CS student at the <a
              href="https://www.uh.edu/"
              target="_blank"
              rel="noreferrer"
              alt="University of Houston"
              className="hover:underline hover:text-red-500"
            >University of Houston</a>
        </p>
        <div className="flex justify-center items-center">
          <p className="md:text-4xl sm:text-3xl text-xl font-bold">
            Interests in
          </p>
          <Typed
            className="md:text-4xl sm:text-3xl text-xl font-bold pl-2"
            strings={[
              "Software Engineer",
              "FullStack Development",
              "Cyber Security",
            ]}
            typeSpeed={30}
            backSpeed={50}
            loop
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
