import { leaderships } from '../constants';

const Leadership = () => {
  return (
    <section >
      <div className="m-auto max-w-6xl">
        {leaderships.map((leadership) => (
          <div key={leadership.id} className="sm:flex sm-flex-row-reverse py-5 ">
            <div className='bg-gray-200 pl-8 space-y-4 w-[350px] h-fit sm:w-[900px] border-r-4px'>
              <h1 className='text-[32px]'>{leadership.org}</h1>
              <div className='flex sm:flex-row flex-col space-x-4'>
                <h3 className='text-bold text-[18px] sm:text-[26px]'>{leadership.title} |</h3> 
                <h3 className='text-[18px] sm:text-[26px]'>{leadership.year} </h3>
              </div>
              <ul className='list-disc'>
                <li className='py-2'>{leadership.task1}</li>  
                <li className='py-2'>{leadership.task2}</li>
                <li className='py-2'>{leadership.task3}</li>
              </ul>
              <a href={leadership.link} target="_blank" rel="noreferrer"><u>{leadership.org}</u></a>
            </div>
            <img src={leadership.image} alt='leadership-images' className='h-[400px] w-[400px] lg:h-fit lg:w-[500px] md:mx-auto py-5 pl-5'/>
            
          </div>
        ))}
      </div>
      

    </section>
  )
}

export default Leadership