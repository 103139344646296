import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <h1 className='w-full text-3xl font-bold text-[#00df9a]'>EKP</h1>
      <ul className='hidden md:flex'>
        <Link to="/" className='p-4 hover:bg-sky-500'>Home</Link>
        <Link to="/about" className='p-4 hover:bg-sky-500'>About</Link>
        <Link to="/projects" className='p-4 hover:bg-sky-500'>Projects</Link>
        <Link to="/experience" className='p-4 hover:bg-sky-500'>Experience</Link>
        <Link to="/clubs"className='p-4 hover:bg-sky-500'>Clubs</Link>
        <Link to="/contact"className='p-4 hover:bg-sky-500'>Contact</Link>
      </ul>
      <div onClick={handleToggle} className='block md:hidden'>
        {toggle ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <div className={toggle ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'} >
        <h1 className='w-full text-3xl font-bold m-4 text-[#00df9a]'>EKP</h1>
        <ul className='p-4 flex flex-col'>
          <Link to="/" className='p-4 border-b border-gray-600 hover:bg-sky-500 '>Home</Link>
          <Link to="/about" className='p-4 border-b border-gray-600 hover:bg-sky-500'>About</Link>
          <Link to="/projects" className='p-4 border-b border-gray-600 hover:bg-sky-500'>Projects</Link>
          <Link to="/experience" className='p-4 border-b border-gray-600 hover:bg-sky-500'>Experince</Link>
          <Link to="/clubs"className='p-4 hover:bg-sky-500'>Clubs</Link>
          <Link to="/contact"className='p-4 hover:bg-sky-500'>Contact</Link>
        </ul>
      </div>
    </div>
  )
}

export default Navbar