import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from "./components/Navbar";
import { Home, About, Projects, Experience, Clubs, Contact } from './pages'



const App = () => {
  return (
    <BrowserRouter> 
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/> 
        <Route path="/projects" element={<Projects />}/> 
        <Route path="/experience" element={<Experience />}/>  
        <Route path="/clubs" element={<Clubs />}/>  
        <Route path="/contact" element={<Contact />}/>  
      </Routes>
      <Footer />
      
    </BrowserRouter>
  );
}

export default App;
