import { experiences } from '../constants'

const Experience = () => {
  return (
    <section>
      <div className="m-auto max-w-4xl min-h-screen">
        {experiences.map((experience) => (
          <div key={experience.id} className="sm:flex sm-flex-row-reverse py-5 ">
            <div className='bg-gray-200 pl-8 space-y-1 w-[350px] h-fit sm:w-[900px] border-r-4px'>
              <h1 className='text-[32px]'>{experience.company}</h1>
              <h3 className='text-xl'>{experience.title}   </h3>
              <h3 className='text-xl'>{experience.year} </h3>
              <ul className='list-disc'>
                <li className='py-2'>{experience.task1}</li>
                <li className='py-2'>{experience.task2}</li>
                <li className='py-2'>{experience.task3}</li>
              </ul>
            </div>
            <img src={experience.image} alt='experience-images' className='h-[400px] w-[400px] lg:h-fit lg:w-[300px] md:mx-auto py-5 pl-5'/>
          </div>
        ))}
      </div>

    </section>
  )
}

export default Experience