import { useEffect } from "react"

function Filter( {setCategory, category, setFiltered, item} ) {
  useEffect(() => {
    if (category === 0) {
      setFiltered(item);
      return;
    }
    const filtered = item.filter((p) =>
      p.project_type.includes(category)
    );
    setFiltered(filtered);
  })
  
  const buttonStyle = "bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded";
  
  return (
    <div className="pl-5">
      <div className="flex justify-center pb-2">
        <h3 className="text-[32px]">Categories</h3>
      </div>
      <div className="flex flex-col sm:flex-row space-x-none sm:space-x-20 justify-center">
        <button onClick={() => setCategory("")} className={buttonStyle}>All</button>
        <button onClick={() => setCategory("Web App")} className={buttonStyle}>Web App</button>
        <button onClick={() => setCategory("Game")} className={buttonStyle}>Game </button>
        <button onClick={() => setCategory("School")} className={buttonStyle}>School</button>
      </div>
    </div>
    
  )
}

export default Filter