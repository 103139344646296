import React from 'react'

const About = () => {
  return (
    <div className='text-white space-y-10 max-w-[800px] w-full md:min-h-screen mx-auto flex flex-col'>
       <div className="">
          <h1 className="font-semibold text-[20px] md:text-[30px]">About Me</h1>
          <p >
            Hello, my name is Erick-Khai Pham and I'm a computer science major at
            the University of Houston Main Campus. I'm a curious student that
            loves coding and building projects, as well as meeting people with
            similar interests. Additionally, I've a strong inclination to learn
            and have a large spectrum of notes in Notion. I've experience in 
            developing web applications using the MERN Stack and usage of Python.To
            add on, I've history with working in a team environment and initiating
            action.
          </p>
          <br />
          <a
            href="/assets/Erick Khai Pham Resume.pdf"
            target="_blank"
            className="bg-blue-400 rounded-full font-bold py-2 px-6"
          >
            Resume
          </a>  
        </div>
        <div className=''>
          <h1 className="font-semibold text-[20px] md:text-[30px]">Education</h1>
          <p>
            CS major & Math minor at the University of Houston
          <p/>
          </p>
          <p>Classificaiton: Senior</p>
          <p>Expected Graduation Date: <span className="bg-yellow-600">December 2023</span></p>
          <p>
            Classes Taken: Programming & Data Structures, Computer Architecutre,
            Operating Systems, Algorithms & Complexities, Automata &
            Computability, Database Systems
          </p>
        </div>

        <div>
          <h1 className="font-semibold text-[20px] md:text-[30px] pt-5"> Skills</h1>
          <p>Languages: Python, JavaScript, HTML/CSS, SQL, PHP</p>
          <p>
            Full Stack: React.js, Node.js, Bootstrap, TailwindCSS, Mongoose, Express.js,
            MongoDB
          </p>
          <p>Developer Tools: Git/GitHub, VSCode, Postman, MySQL Workbench, Docker</p>
        </div> 
    </div>
  )
}

export default About